function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
import { getDecodedParamFromQuery, getQueryStringAsHash } from "utils/url";
import { getCookie } from "/services/cookies-service";
import { BIG_BRAIN_VISITOR_ID, CLUSTER_COOKIE } from "/constants/cookies";
import { getEnrichmentEntriesForSales } from "services/sales-enrichment/sales-enrichment-service";
import { getPricingVersionForSales } from "services/pricing/pricing-model/services";
import { HUBSPOT_API_AB_TEST_NAME, HUBSPOT_API_CLUSTER, HUBSPOT_API_FROM, HUBSPOT_API_PRICING_VERSION, HUBSPOT_API_PRIVACY_CONSENT, HUBSPOT_API_RECENT_CONVERSION_REFERRER_PAGE, HUBSPOT_API_RECENT_CONVERSION_URL, HUBSPOT_API_SOURCE, HUBSPOT_API_VISITOR, URL_PARAM_FROM, WEBSITE_BOT_HELP } from "./hubspot-constants";
const HUBSPOT_FORM_URL = "https://api.hsforms.com/submissions/v3/integration/submit";
export const submitHubspotForm = function() {
    var _ref = _async_to_generator(function*(portalId, formId, emailValue) {
        const url = `${HUBSPOT_FORM_URL}/${portalId}/${formId}`;
        const utmParams = getUtmParams();
        const data = {
            fields: [
                {
                    name: "email",
                    value: emailValue
                }
            ].concat(utmParams)
        };
        try {
            const serverResponse = yield fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });
            if (serverResponse.status !== 200) {
                throw new Error(serverResponse.statusText);
            }
            yield serverResponse.json();
            return true;
        } catch (err) {
            console.log("err", err);
            return false;
        }
    });
    return function submitHubspotForm(portalId, formId, emailValue) {
        return _ref.apply(this, arguments);
    };
}();
const getUtmParams = ()=>{
    const url = window.location;
    const queryString = getQueryStringAsHash(url);
    const utmParams = [];
    const utm_source = queryString.marketing_source || queryString.utm_source;
    if (utm_source) utmParams.push({
        name: "utm_source",
        value: utm_source
    });
    const utm_campaign = queryString.marketing_campaign || queryString.utm_campaign;
    if (utm_campaign) utmParams.push({
        name: "utm_campaign",
        value: utm_campaign
    });
    const utm_banner = queryString.marketing_banner || queryString.utm_banner;
    if (utm_banner) utmParams.push({
        name: "utm_banner",
        value: utm_banner
    });
    const utm_content = queryString.marketing_content || queryString.utm_content;
    if (utm_content) utmParams.push({
        name: "utm_content",
        value: utm_content
    });
    const utm_cluster_id = getCookie(CLUSTER_COOKIE);
    if (utm_cluster_id) utmParams.push({
        name: "utm_cluster_id",
        value: utm_cluster_id
    });
    return utmParams;
};
export const getSingleUrlParamToHubspotForm = (location, urlParamName, hubspotApiName)=>{
    return {
        name: hubspotApiName,
        value: getDecodedParamFromQuery(location, urlParamName)
    };
};
export const sendHubspotForm = function() {
    var _ref = _async_to_generator(function*(portalId, formId, fields) {
        try {
            const prompt = fields["how_can_our_consulting_team_help_you"];
            const startTime = performance.now();
            const serverResponse = yield fetch("/dynamic/ask-ai", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    prompt
                })
            });
            const endTime = performance.now();
            const time = endTime - startTime;
            const timeInSeconds = time / 1000;
            console.log("Time in seconds: ", timeInSeconds);
            const aiResponse = yield serverResponse.json();
            console.log(aiResponse);
            return true;
        } catch (err) {
            console.log("err", err);
            return false;
        }
    });
    return function sendHubspotForm(portalId, formId, fields) {
        return _ref.apply(this, arguments);
    };
}();
export const generateHiddenData = ({ withPrivacyNotice, source, overrideCampaignId, abTests, cookies, cloudfrontViewerCountry, referrePage, bookAMeetingTestName, adaChatbotTest })=>{
    var _window_location, _window;
    const UTM_CAMPAIGN_PARAM_NAME = "utm_campaign";
    let urlParams = getEnrichmentEntriesForSales();
    if (overrideCampaignId) {
        const urlParamsWithoutCampaign = urlParams.filter((param)=>(param === null || param === void 0 ? void 0 : param.name) !== UTM_CAMPAIGN_PARAM_NAME);
        urlParamsWithoutCampaign.push({
            name: UTM_CAMPAIGN_PARAM_NAME,
            value: overrideCampaignId
        });
        urlParams = urlParamsWithoutCampaign;
    }
    const hiddenData = [
        ...urlParams
    ];
    const visitorId = getCookie(BIG_BRAIN_VISITOR_ID) || "";
    hiddenData.push({
        name: HUBSPOT_API_VISITOR,
        value: visitorId
    });
    const pricingVersion = getPricingVersionForSales({
        cookies,
        abTests,
        countryCode: cloudfrontViewerCountry
    }) || "";
    hiddenData.push({
        name: HUBSPOT_API_PRICING_VERSION,
        value: pricingVersion
    });
    const clusterId = getCookie(CLUSTER_COOKIE) || "";
    hiddenData.push({
        name: HUBSPOT_API_CLUSTER,
        value: clusterId
    });
    hiddenData.push({
        name: HUBSPOT_API_SOURCE,
        value: source
    });
    hiddenData.push({
        name: HUBSPOT_API_PRIVACY_CONSENT,
        value: withPrivacyNotice
    });
    hiddenData.push({
        name: HUBSPOT_API_RECENT_CONVERSION_URL,
        value: (_window = window) === null || _window === void 0 ? void 0 : (_window_location = _window.location) === null || _window_location === void 0 ? void 0 : _window_location.href
    });
    if (referrePage) {
        hiddenData.push({
            name: HUBSPOT_API_RECENT_CONVERSION_REFERRER_PAGE,
            value: referrePage
        });
    }
    if (bookAMeetingTestName) {
        hiddenData.push({
            name: HUBSPOT_API_AB_TEST_NAME,
            value: bookAMeetingTestName
        });
    }
    if (adaChatbotTest) {
        hiddenData.push({
            name: WEBSITE_BOT_HELP,
            value: true
        });
    }
    const fromUrlParamObject = getSingleUrlParamToHubspotForm(window.location, URL_PARAM_FROM, HUBSPOT_API_FROM);
    if (fromUrlParamObject.value) hiddenData.push(fromUrlParamObject);
    return hiddenData;
};
